// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #09d3ac;
// }
.connectContainer,
.mintContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background-color: transparent;
  color: #fff;
  p {
    text-align: center;
  }
  .title {
    text-align: center;
    position: relative;
    padding: 20px 30px;
    h2 {
      font-size: 35px;
      @media screen and (max-width: 1550px) {
        font-size: 30px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 25px;
        line-height: 30px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 20px;
        line-height: 25px;
      }
    }
    img {
      position: absolute;
      transform: scale(0.8);
      &:nth-child(1) {
        top: 0;
        left: 0;
      }
      &:nth-child(2) {
        bottom: 0;
        right: 0;
      }
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    cursor: pointer;
    transition: all 0.4s;
    color: rgb(58, 54, 131);
    background-color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 18px;
    @media screen and (max-width: 1550px) {
      font-size: 16px;
    }
    @media screen and (max-width: 1350px) {
      font-size: 13px;
    }
    @media screen and (max-width: 820px) {
      font-size: 11px;
    }
    &:hover:not(:disabled) {
      box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.295);
    }
    border-radius: 15px;
    border: 0;
    outline: none;
    &:disabled {
      opacity: 0.5;
      cursor: initial;
    }
    img {
      margin-right: 5px;
      filter: invert(18%) sepia(42%) saturate(2685%) hue-rotate(226deg)
        brightness(30%) contrast(94%);
    }
  }
}
.mintContainer {
  height: 300px;
  p {
    margin-top: 10px !important;
    font-size: 17px;
    @media screen and (max-width: 1550px) {
      font-size: 15px;
    }
    @media screen and (max-width: 1350px) {
      font-size: 13px;
    }
  }
  form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .counter {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-weight: 700;
      font-size: 1.5em;
      @media screen and (max-width: 1440px) {
        font-size: 1em;
      }
      p {
        margin-top: 0 !important;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-size: 2.5em;
          width: 60px;
          text-align: center;
        }
        button {
          background-color: transparent;
          color: white;
          font-size: 30px;
          &:hover {
            transform: scale(1.2);
            box-shadow: none;
          }
          &:active {
            transform: scale(0.8);
          }
        }
      }
    }
  }
}
.connectContainer {
  height: 230px;
  .underTitle {
    margin-top: 0 !important;
    font-size: 17px !important;
    @media screen and (max-width: 1550px) {
      font-size: 15px !important;
    }
    @media screen and (max-width: 1350px) {
      font-size: 13px !important;
    }
  }
}
// .connectContainer {
//   row-gap: 50px;
// }
// .mintContainer {
//   justify-content: flex-start;
//   row-gap: 20px;
//   form {
//     width: 100%;
//     padding: 20px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     row-gap: 10px;
//     color: #fff;
//     input {
//       color: #fff;
//     }
//   }
// }
