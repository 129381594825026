.container {
  background: url("../assets/Main_BG_JP.jpg") no-repeat;
  background-size: 100% 100%;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  @media screen and (max-width: 768px) {
    background-size: 300% 100%;
  }
  header {
    width: 900px;
    height: 120px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background-color: rgba(5, 14, 78, 0.3);
    border-radius: 0 0 25px 25px;
    box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.23),
      0px 0px 4px 2px rgba(0, 0, 0, 0.2) inset;
    @media screen and (max-width: 1000px) {
      width: 95%;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: space-between;
      height: 150px;
    }
    img {
      height: 90%;
      @media screen and (max-width: 768px) {
        height: 60px;
      }
    }
    .timerContainer {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      font-family: "Kanit", sans-serif;
      -webkit-text-size-adjust: none;
      @media screen and (max-width: 1600px) {
        font-size: 30px;
      }
      @media screen and (max-width: 1350px) {
        font-size: 25px;
      }
      @media screen and (max-width: 800px) {
        justify-content: center;
      }
      @media screen and (max-width: 375px) {
        font-size: 20px;
      }
      span {
        font-size: 20px;
        font-weight: 900;
        text-align: center;
        text-transform: uppercase;
        line-height: 40px;
        margin: 0 15px;
        @media screen and (max-width: 1600px) {
          font-size: 15px;
          line-height: 30px;
        }
        @media screen and (max-width: 1350px) {
          font-size: 12px;
          line-height: 20px;
        }
        @media screen and (max-width: 375px) {
          font-size: 10px;
        }
        &::first-line {
          font-size: 50px;
          @media screen and (max-width: 1600px) {
            font-size: 40px;
          }
          @media screen and (max-width: 1350px) {
            font-size: 30px;
          }
          @media screen and (max-width: 375px) {
            font-size: 25px;
          }
        }
      }
    }
  }
  main {
    width: 100%;
    height: auto;
    min-height: calc(100vh - 100px);
    padding: 20px 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    color: #fff;
    @media screen and (max-width: 1440px) {
      padding: 20px 5vw;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
    }
    & > div {
      width: 50%;
      height: 100%;
      @media screen and (max-width: 768px) {
        width: 80%;
      }
      @media screen and (max-width: 500px) {
        width: 100%;
      }
      &:nth-child(1) {
        background-color: rgba(5, 14, 78, 0.15);
        border-radius: 25px;
        box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.23),
          0px 0px 4px 2px rgba(0, 0, 0, 0.2) inset;
        padding: 10px 70px 40px 70px;
        @media screen and (max-width: 1440px) {
          padding: 0px 40px 30px 40px;
        }
        @media screen and (max-width: 550px) {
          padding: 0px 20px 40px 20px;
        }
        p {
          margin-top: 30px;
          font-weight: 300;
          font-size: 13px;
          @media screen and (max-width: 1550px) {
            font-size: 11px;
          }
          @media screen and (max-width: 1350px) {
            font-size: 9px;
          }
          span {
            font-weight: 700;
          }
        }
        .howto {
          margin-left: auto;
          margin-right: auto;
          width: 60%;
          font-weight: 500;
          font-size: 15px;
          @media screen and (max-width: 1550px) {
            font-size: 13px;
          }
          @media screen and (max-width: 1350px) {
            font-size: 11px;
          }
          @media screen and (max-width: 1024px) {
            width: 100%;
          }
          span {
            font-weight: 700;
          }
        }
      }
      &:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          width: 85%;
          &:nth-child(2),
          &:nth-child(3),
          &.cardImg {
            position: absolute;
            width: 65%;
          }
          &.cardImg {
            width: 50%;
            top: 17%;
            left: 50%;
            transform: translateX(-50%);
          }
          &:nth-child(2) {
            top: 2%;
            left: 49.5%;
            transform: translateX(-50%);
          }
          &:nth-child(3) {
            bottom: 18%;
            left: 52%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
  footer {
    position: absolute;
    width: 100vw;
    height: 230px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #141623;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 17px;
    padding: 10px 70px;
    color: #fff;
    @media screen and (max-width: 768px) {
      //flex-direction: column;
      height: 520px;
      justify-content: flex-start;
      padding: 10px 20px 60px 20px;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 1550px) {
      font-size: 15px;
    }
    @media screen and (max-width: 1350px) {
      font-size: 13px;
    }
    & > div {
      img {
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          transform: scale(1.05);
        }
      }
      &:nth-child(1) {
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        row-gap: 25px;
        @media screen and (max-width: 1000px) {
          img {
            width: 30px;
          }
        }
        @media screen and (max-width: 768px) {
          order: 2;
          align-items: center;
          width: 50%;
          height: 50%;
          padding-left: 30px;
        }
        div {
          display: flex;
          column-gap: 15px;
        }
        a {
          text-decoration: none;
          color: #fff;
        }
      }
      &:nth-child(2) {
        height: 100%;
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        row-gap: 25px;
        @media screen and (max-width: 768px) {
          width: 100%;
          order: 1;
          height: 50%;
        }
        hr {
          width: 90%;
          background-color: #181c34;
          border: 1.5px solid #181c34;
        }
        div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          @media screen and (max-width: 768px) {
            padding-left: 20px;
          }
          img {
            @media screen and (max-width: 1000px) {
              width: 130px;
            }
            @media screen and (max-width: 768px) {
              width: 50%;
            }
          }
        }
      }
      &:nth-child(3) {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 20px;
        @media screen and (max-width: 1000px) {
          margin-bottom: 83px;
          img {
            width: 30px;
          }
        }
        @media screen and (max-width: 768px) {
          order: 3;
          width: 50%;
          color: rgba(255, 255, 255, 0);
          font-size: 1px;
          margin-bottom: 83px;
          justify-content: flex-start;
          padding-left: 30px;
        }
      }
    }
  }
}
