@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  -webkit-text-size-adjust: none;
}

.Main_container__2udZi {
  background: url(/static/media/Main_BG_JP.931cb330.jpg) no-repeat;
  background-size: 100% 100%;
  width: 100vw;
  height: auto;
  min-height: 100vh;
}
@media screen and (max-width: 768px) {
  .Main_container__2udZi {
    background-size: 300% 100%;
  }
}
.Main_container__2udZi header {
  width: 900px;
  height: 120px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: rgba(5, 14, 78, 0.3);
  border-radius: 0 0 25px 25px;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.23), 0px 0px 4px 2px rgba(0, 0, 0, 0.2) inset;
}
@media screen and (max-width: 1000px) {
  .Main_container__2udZi header {
    width: 95%;
  }
}
@media screen and (max-width: 768px) {
  .Main_container__2udZi header {
    flex-direction: column;
    justify-content: space-between;
    height: 150px;
  }
}
.Main_container__2udZi header img {
  height: 90%;
}
@media screen and (max-width: 768px) {
  .Main_container__2udZi header img {
    height: 60px;
  }
}
.Main_container__2udZi header .Main_timerContainer__2uDM7 {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-family: "Kanit", sans-serif;
  -webkit-text-size-adjust: none;
}
@media screen and (max-width: 1600px) {
  .Main_container__2udZi header .Main_timerContainer__2uDM7 {
    font-size: 30px;
  }
}
@media screen and (max-width: 1350px) {
  .Main_container__2udZi header .Main_timerContainer__2uDM7 {
    font-size: 25px;
  }
}
@media screen and (max-width: 800px) {
  .Main_container__2udZi header .Main_timerContainer__2uDM7 {
    justify-content: center;
  }
}
@media screen and (max-width: 375px) {
  .Main_container__2udZi header .Main_timerContainer__2uDM7 {
    font-size: 20px;
  }
}
.Main_container__2udZi header .Main_timerContainer__2uDM7 span {
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  line-height: 40px;
  margin: 0 15px;
}
@media screen and (max-width: 1600px) {
  .Main_container__2udZi header .Main_timerContainer__2uDM7 span {
    font-size: 15px;
    line-height: 30px;
  }
}
@media screen and (max-width: 1350px) {
  .Main_container__2udZi header .Main_timerContainer__2uDM7 span {
    font-size: 12px;
    line-height: 20px;
  }
}
@media screen and (max-width: 375px) {
  .Main_container__2udZi header .Main_timerContainer__2uDM7 span {
    font-size: 10px;
  }
}
.Main_container__2udZi header .Main_timerContainer__2uDM7 span::first-line {
  font-size: 50px;
}
@media screen and (max-width: 1600px) {
  .Main_container__2udZi header .Main_timerContainer__2uDM7 span::first-line {
    font-size: 40px;
  }
}
@media screen and (max-width: 1350px) {
  .Main_container__2udZi header .Main_timerContainer__2uDM7 span::first-line {
    font-size: 30px;
  }
}
@media screen and (max-width: 375px) {
  .Main_container__2udZi header .Main_timerContainer__2uDM7 span::first-line {
    font-size: 25px;
  }
}
.Main_container__2udZi main {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 100px);
  padding: 20px 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: #fff;
}
@media screen and (max-width: 1440px) {
  .Main_container__2udZi main {
    padding: 20px 5vw;
  }
}
@media screen and (max-width: 768px) {
  .Main_container__2udZi main {
    flex-direction: column-reverse;
  }
}
.Main_container__2udZi main > div {
  width: 50%;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .Main_container__2udZi main > div {
    width: 80%;
  }
}
@media screen and (max-width: 500px) {
  .Main_container__2udZi main > div {
    width: 100%;
  }
}
.Main_container__2udZi main > div:nth-child(1) {
  background-color: rgba(5, 14, 78, 0.15);
  border-radius: 25px;
  box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.23), 0px 0px 4px 2px rgba(0, 0, 0, 0.2) inset;
  padding: 10px 70px 40px 70px;
}
@media screen and (max-width: 1440px) {
  .Main_container__2udZi main > div:nth-child(1) {
    padding: 0px 40px 30px 40px;
  }
}
@media screen and (max-width: 550px) {
  .Main_container__2udZi main > div:nth-child(1) {
    padding: 0px 20px 40px 20px;
  }
}
.Main_container__2udZi main > div:nth-child(1) p {
  margin-top: 30px;
  font-weight: 300;
  font-size: 13px;
}
@media screen and (max-width: 1550px) {
  .Main_container__2udZi main > div:nth-child(1) p {
    font-size: 11px;
  }
}
@media screen and (max-width: 1350px) {
  .Main_container__2udZi main > div:nth-child(1) p {
    font-size: 9px;
  }
}
.Main_container__2udZi main > div:nth-child(1) p span {
  font-weight: 700;
}
.Main_container__2udZi main > div:nth-child(1) .Main_howto__1yBSH {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  font-weight: 500;
  font-size: 15px;
}
@media screen and (max-width: 1550px) {
  .Main_container__2udZi main > div:nth-child(1) .Main_howto__1yBSH {
    font-size: 13px;
  }
}
@media screen and (max-width: 1350px) {
  .Main_container__2udZi main > div:nth-child(1) .Main_howto__1yBSH {
    font-size: 11px;
  }
}
@media screen and (max-width: 1024px) {
  .Main_container__2udZi main > div:nth-child(1) .Main_howto__1yBSH {
    width: 100%;
  }
}
.Main_container__2udZi main > div:nth-child(1) .Main_howto__1yBSH span {
  font-weight: 700;
}
.Main_container__2udZi main > div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.Main_container__2udZi main > div:nth-child(2) img {
  width: 85%;
}
.Main_container__2udZi main > div:nth-child(2) img:nth-child(2), .Main_container__2udZi main > div:nth-child(2) img:nth-child(3), .Main_container__2udZi main > div:nth-child(2) img.Main_cardImg__3ap4M {
  position: absolute;
  width: 65%;
}
.Main_container__2udZi main > div:nth-child(2) img.Main_cardImg__3ap4M {
  width: 50%;
  top: 17%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Main_container__2udZi main > div:nth-child(2) img:nth-child(2) {
  top: 2%;
  left: 49.5%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Main_container__2udZi main > div:nth-child(2) img:nth-child(3) {
  bottom: 18%;
  left: 52%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Main_container__2udZi footer {
  position: absolute;
  width: 100vw;
  height: 230px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #141623;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 17px;
  padding: 10px 70px;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .Main_container__2udZi footer {
    height: 520px;
    justify-content: flex-start;
    padding: 10px 20px 60px 20px;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1550px) {
  .Main_container__2udZi footer {
    font-size: 15px;
  }
}
@media screen and (max-width: 1350px) {
  .Main_container__2udZi footer {
    font-size: 13px;
  }
}
.Main_container__2udZi footer > div img {
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.Main_container__2udZi footer > div img:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.Main_container__2udZi footer > div:nth-child(1) {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  grid-row-gap: 25px;
  row-gap: 25px;
}
@media screen and (max-width: 1000px) {
  .Main_container__2udZi footer > div:nth-child(1) img {
    width: 30px;
  }
}
@media screen and (max-width: 768px) {
  .Main_container__2udZi footer > div:nth-child(1) {
    order: 2;
    align-items: center;
    width: 50%;
    height: 50%;
    padding-left: 30px;
  }
}
.Main_container__2udZi footer > div:nth-child(1) div {
  display: flex;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
     -moz-column-gap: 15px;
          column-gap: 15px;
}
.Main_container__2udZi footer > div:nth-child(1) a {
  text-decoration: none;
  color: #fff;
}
.Main_container__2udZi footer > div:nth-child(2) {
  height: 100%;
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-row-gap: 25px;
  row-gap: 25px;
}
@media screen and (max-width: 768px) {
  .Main_container__2udZi footer > div:nth-child(2) {
    width: 100%;
    order: 1;
    height: 50%;
  }
}
.Main_container__2udZi footer > div:nth-child(2) hr {
  width: 90%;
  background-color: #181c34;
  border: 1.5px solid #181c34;
}
.Main_container__2udZi footer > div:nth-child(2) div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media screen and (max-width: 768px) {
  .Main_container__2udZi footer > div:nth-child(2) div {
    padding-left: 20px;
  }
}
@media screen and (max-width: 1000px) {
  .Main_container__2udZi footer > div:nth-child(2) div img {
    width: 130px;
  }
}
@media screen and (max-width: 768px) {
  .Main_container__2udZi footer > div:nth-child(2) div img {
    width: 50%;
  }
}
.Main_container__2udZi footer > div:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}
@media screen and (max-width: 1000px) {
  .Main_container__2udZi footer > div:nth-child(3) {
    margin-bottom: 83px;
  }
  .Main_container__2udZi footer > div:nth-child(3) img {
    width: 30px;
  }
}
@media screen and (max-width: 768px) {
  .Main_container__2udZi footer > div:nth-child(3) {
    order: 3;
    width: 50%;
    color: rgba(255, 255, 255, 0);
    font-size: 1px;
    margin-bottom: 83px;
    justify-content: flex-start;
    padding-left: 30px;
  }
}
.App_connectContainer__2R0Hv,
.App_mintContainer__3jKT9 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background-color: transparent;
  color: #fff;
}
.App_connectContainer__2R0Hv p,
.App_mintContainer__3jKT9 p {
  text-align: center;
}
.App_connectContainer__2R0Hv .App_title__2CyN4,
.App_mintContainer__3jKT9 .App_title__2CyN4 {
  text-align: center;
  position: relative;
  padding: 20px 30px;
}
.App_connectContainer__2R0Hv .App_title__2CyN4 h2,
.App_mintContainer__3jKT9 .App_title__2CyN4 h2 {
  font-size: 35px;
}
@media screen and (max-width: 1550px) {
  .App_connectContainer__2R0Hv .App_title__2CyN4 h2,
.App_mintContainer__3jKT9 .App_title__2CyN4 h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 1350px) {
  .App_connectContainer__2R0Hv .App_title__2CyN4 h2,
.App_mintContainer__3jKT9 .App_title__2CyN4 h2 {
    font-size: 25px;
    line-height: 30px;
  }
}
@media screen and (max-width: 1000px) {
  .App_connectContainer__2R0Hv .App_title__2CyN4 h2,
.App_mintContainer__3jKT9 .App_title__2CyN4 h2 {
    font-size: 20px;
    line-height: 25px;
  }
}
.App_connectContainer__2R0Hv .App_title__2CyN4 img,
.App_mintContainer__3jKT9 .App_title__2CyN4 img {
  position: absolute;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}
.App_connectContainer__2R0Hv .App_title__2CyN4 img:nth-child(1),
.App_mintContainer__3jKT9 .App_title__2CyN4 img:nth-child(1) {
  top: 0;
  left: 0;
}
.App_connectContainer__2R0Hv .App_title__2CyN4 img:nth-child(2),
.App_mintContainer__3jKT9 .App_title__2CyN4 img:nth-child(2) {
  bottom: 0;
  right: 0;
}
.App_connectContainer__2R0Hv button,
.App_mintContainer__3jKT9 button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  color: #3a3683;
  background-color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
  border-radius: 15px;
  border: 0;
  outline: none;
}
@media screen and (max-width: 1550px) {
  .App_connectContainer__2R0Hv button,
.App_mintContainer__3jKT9 button {
    font-size: 16px;
  }
}
@media screen and (max-width: 1350px) {
  .App_connectContainer__2R0Hv button,
.App_mintContainer__3jKT9 button {
    font-size: 13px;
  }
}
@media screen and (max-width: 820px) {
  .App_connectContainer__2R0Hv button,
.App_mintContainer__3jKT9 button {
    font-size: 11px;
  }
}
.App_connectContainer__2R0Hv button:hover:not(:disabled),
.App_mintContainer__3jKT9 button:hover:not(:disabled) {
  box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.295);
}
.App_connectContainer__2R0Hv button:disabled,
.App_mintContainer__3jKT9 button:disabled {
  opacity: 0.5;
  cursor: auto;
  cursor: initial;
}
.App_connectContainer__2R0Hv button img,
.App_mintContainer__3jKT9 button img {
  margin-right: 5px;
  -webkit-filter: invert(18%) sepia(42%) saturate(2685%) hue-rotate(226deg) brightness(30%) contrast(94%);
          filter: invert(18%) sepia(42%) saturate(2685%) hue-rotate(226deg) brightness(30%) contrast(94%);
}

.App_mintContainer__3jKT9 {
  height: 300px;
}
.App_mintContainer__3jKT9 p {
  margin-top: 10px !important;
  font-size: 17px;
}
@media screen and (max-width: 1550px) {
  .App_mintContainer__3jKT9 p {
    font-size: 15px;
  }
}
@media screen and (max-width: 1350px) {
  .App_mintContainer__3jKT9 p {
    font-size: 13px;
  }
}
.App_mintContainer__3jKT9 form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.App_mintContainer__3jKT9 form .App_counter__1I_dR {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 700;
  font-size: 1.5em;
}
@media screen and (max-width: 1440px) {
  .App_mintContainer__3jKT9 form .App_counter__1I_dR {
    font-size: 1em;
  }
}
.App_mintContainer__3jKT9 form .App_counter__1I_dR p {
  margin-top: 0 !important;
}
.App_mintContainer__3jKT9 form .App_counter__1I_dR div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.App_mintContainer__3jKT9 form .App_counter__1I_dR div span {
  font-size: 2.5em;
  width: 60px;
  text-align: center;
}
.App_mintContainer__3jKT9 form .App_counter__1I_dR div button {
  background-color: transparent;
  color: white;
  font-size: 30px;
}
.App_mintContainer__3jKT9 form .App_counter__1I_dR div button:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  box-shadow: none;
}
.App_mintContainer__3jKT9 form .App_counter__1I_dR div button:active {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.App_connectContainer__2R0Hv {
  height: 230px;
}
.App_connectContainer__2R0Hv .App_underTitle__17srZ {
  margin-top: 0 !important;
  font-size: 17px !important;
}
@media screen and (max-width: 1550px) {
  .App_connectContainer__2R0Hv .App_underTitle__17srZ {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1350px) {
  .App_connectContainer__2R0Hv .App_underTitle__17srZ {
    font-size: 13px !important;
  }
}
